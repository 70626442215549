#accordion-item-panel-input-payload {
  padding: 0;
  margin: 0;
}

#accordion-item-panel-output-payload {
  padding: 0;
  margin: 0;
}

#accordion-item-panel-invocation-payload {
  padding: 0;
  margin: 0;
}


#payloadBox {
  position: relative;
  width: 570px;
}

#codeBox {
  position: relative;
}

#copyBtn {
  position: absolute;
  z-index:1000;
  float: right;
  top: 20px;
  right: 20px;
}
