.PageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px;
  gap: 20px;
  width: 100%;
  height: 100vh;
  background: #e3e8f1;
}

.TopNav {
  position: fixed;
  width: 100%;
}

.SignInForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 60px 40px 40px;
  gap: 40px;
  width: 560px;
  height: auto;
  background: #ffffff;
}

.LoginHeaderText {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  color: #25262e;
}

.SignInFields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
  width: 360px;
  height: auto;
}

.Links {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #1c6ee6;
}

.WideFlex {
  width: 100%;
}

.LicenseAndAgreements {
  width: 428px;
  height: 17px;
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #737482;
}

.LicenseLink {
  text-decoration-line: underline;
  color: #1c6ee6;
}

.LeftDivider {
  margin-right: 5px;
}

.RightDivider {
  margin-left: 5px;
}
