.spanLabel {
  color: #3E3F4D;
}

.spanDetail {
  color: #737482;
  font-size: small;
}

li[aria-selected="true"] > label > div > span {
  color: #fff !important;
}

li[aria-selected="true"] > label > div > span > span {
  color: #fff !important;
}
